@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.ds-onecoltextbody {
  padding: 30px 15px;

  @include breakpoint($desktop) {
    padding: 45px 15px 45px;
  }

  p {
    //max-width: 940px;
    font-size: 19px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  h2 {
    font-family: $font-headings;
    padding-bottom: 23px;
    font-size: 30px;
    font-weight: 700;

    &:before {
      display: none;
    }

    @include breakpoint($desktop-huge) {
      font-size: 40px;
    }

    color: white
  }
}
